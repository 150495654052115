const userInfoAuth = JSON.parse(localStorage.getItem("userData"));
let auth = [];
const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

if (userInfoAuth && userInfoAuth.authentication) {
  const authentication = userInfoAuth.authentication;


  if (authentication.Master && authentication.Master.length > 0) {
    auth.push("Master");

    const masterAuth = [
      "User_Master",
      "Form_information_master",
      "Banner_master",
      "Category_master",
      "SubCategory_master",
      "Promocode_master",
      "Notification_master",
      "Blog_master",
    ];

    masterAuth.forEach((item) => {
      if (authentication.Master.includes(item)) {
        auth.push(item);
      }
    });
  }

  if (authentication.ProductsMaster !== undefined) {
    auth.push("ProductsMaster");
  }

  if (authentication.FlavourMaster !== undefined) {
    auth.push("FlavourMaster");
  }

  if (authentication.ComboProductsMaster !== undefined) {
    auth.push("ComboProducts");
  }

  if (authentication.OrderHistoryMaster !== undefined) {
    auth.push("Order_History_master");
  }

  if (authentication.SettingsMaster !== undefined) {
    auth.push("SettingsMaster");
  }

  if (authentication.PurchaseInventory !== undefined) {
    auth.push("PurchaseInventory");

    const purchaseAuth = ["PurchaseInventory"];

    purchaseAuth.forEach((item) => {
      if (authentication.PurchaseInventory.includes(item)) {
        auth.push(item);
      }
    });
  }

  if (authentication.ReportMaster && authentication.ReportMaster.length > 0) {
    auth.push("ReportMaster")
    const reportAuth = ["ReportMaster"];

    reportAuth.forEach((item) => {
      if (authentication.ReportMaster.includes(item)) {
        auth.push(item);
      }
    });
  }


  if (authentication.OrdersMaster && authentication.OrdersMaster.length > 0) {
    auth.push("OrdersMaster")
    const orderAuth = ["OrdersMaster"];

    orderAuth.forEach((item) => {
      if (authentication.OrdersMaster.includes(item)) {
        auth.push(item);
      }
    });
  }
}

const sidebarLinks = [
  {
    menu_title: "Dashboard",
    path: "/app/dashboard",
    icon: "dashboard",
    category: "general",
    isMenuOpen: false,
    menu: "dashboard",
    fullPageMenu: false,
    desc: true,
    content: "",
    child_routes: null,
    permission: permission && permission.Dashboard !== undefined
      ? permission && permission.Dashboard.includes("Dashboard")
      : false,
  },
  {
    menu_title: "Master",
    path: "/app/master",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("Master"),
    child_routes: [
      {
        path: "/app/master/user",
        menu_title: "User Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("User_Master"),
      },
      {
        path: "/app/master/tags",
        menu_title: "Tag Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Tag_master")
            : false,
      },
      {
        path: "/app/master/formInformation",
        menu_title: "Form-Information",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Form_information_master"),
      },
      {
        path: "/app/master/banner",
        menu_title: "Banner",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Banner_master"),
      },
      {
        path: "/app/master/category",
        menu_title: "Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Category_master"),
      },
      {
        path: "/app/master/sub-category",
        menu_title: "Sub Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("SubCategory_master")
            : false,
      },
      {
        path: "/app/master/second-sub-category",
        menu_title: "Second Sub Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("SecondSubCategory_master")
            : false,
      },
      {
        path: "/app/master/city",
        menu_title: "City Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("City_master")
            : false,
      },
      {
        path: "/app/master/brand",
        menu_title: "Brand Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Brand_master")
            : false,
      },

      {
        path: "/app/master/supplier",
        menu_title: "Supplier Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Supplier_master")
            : false,
      },
      {
        path: "/app/master/blogs",
        menu_title: "Blogs",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Blog_master"),
      },
      {
        path: "/app/master/promocode",
        menu_title: "Promocode",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Promocode_master"),
      },
      {
        path: "/app/master/notification",
        menu_title: "Notification",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Notification_master"),
      },
      {
        path: "/app/master/faq-category",
        menu_title: "FAQ Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("FAQ_Category_master")
            : false,
      },
      {
        path: "/app/master/faq",
        menu_title: "FAQ Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("FAQ_master")
            : false,
      },
      {
        path: "/app/master/warehouse",
        menu_title: "Warehouse Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Warehouse_master")
            : false,
      },
      {
        path: "/app/master/feeds",
        menu_title: "Feeds Access Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Feeds_master")
            : false,
      },

      {
        path: "/app/master/event",
        menu_title: "Event Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Event_master")
            : false,
      },
    ],
  },
  {
    menu_title: "Products",
    path: "/app/product",
    icon: <i class="fa fa-folder" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "product",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission: auth.includes("ProductsMaster"),
    child_routes: null,
  },
  {
    menu_title: "Flavour Master",
    path: "/app/flavour-variation",
    icon: <i class="fa fa-dropbox" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "flavour-variation",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission: auth.includes("FlavourMaster"),
    child_routes: null,

  },
  {
    menu_title: "Combo Products",
    path: "/app/combo-product",
    icon: <i class="fa fa-list" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "product",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission: auth.includes("ComboProducts"),
    child_routes: null,
  },
  {
    menu_title: "Orders",
    path: "/app/orders",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "orders",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("OrdersMaster"),
    child_routes: [
      {
        path: "/app/orders/admin-orders",
        menu_title: "Admin Orders",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.OrdersMaster !== undefined
            ? permission && permission.OrdersMaster.includes("Admin_orders_report")
            : false,
      },
      {
        path: "/app/orders/warehouse-orders",
        menu_title: "Warehouse Orders",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.OrdersMaster !== undefined
            ? permission &&
            permission.OrdersMaster.includes("Warehouse_orders_report")
            : false,
      },
      {
        path: "/app/orders/replacement",
        menu_title: "Reverse Orders",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.OrdersMaster !== undefined
            ? permission && permission.OrdersMaster.includes("Reverse_orders_report")
            : false,
      },
      {
        path: "/app/orders/warehouse-replacement-orders",
        menu_title: "Warehouse Reverse Orders",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.OrdersMaster !== undefined
            ? permission && permission.OrdersMaster.includes("Warehouse_reverse_orders_report")
            : false,
      },
    ],
  },
  {
    menu_title: "Reports",
    path: "/app/reports",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "",
    isMenuOpen: false,
    menu: "reports",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("ReportMaster"),
    child_routes: [
      {
        path: "/app/reports/demanded-products",
        menu_title: "Demanded Products Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission &&
            permission.ReportMaster.includes("Demanded_products")
            : false,
      },
      {
        path: "/app/reports/purchase-logs",
        menu_title: "Purchase Logs Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("Purchase_logs")
            : false,
      },
      {
        path: "/app/reports/abandoned-cart",
        menu_title: "Abandoned Cart Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("Abandoned_cart")
            : false,
      },
      {
        path: "/app/reports/customers-revenue",
        menu_title: "Customers Revenue Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("CustomersRevenue")
            : false,
      },
      {
        path: "/app/reports/customers-list",
        menu_title: "Customers List Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("CustomersList")
            : false,
      },
      {
        path: "/app/reports/sales-report",
        menu_title: "Sales Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("Sales_report")
            : false,
      },
      {
        path: "/app/reports/expiry-alert-report",
        menu_title: "Expiry Alert Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission &&
            permission.ReportMaster.includes("Expiry_Alert_report")
            : false,
      },
      {
        path: "/app/reports/expired-product-report",
        menu_title: "Expired Product Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission &&
            permission.ReportMaster.includes("Expired_Product_report")
            : false,
      },
    ],
  },
  {
    menu_title: "Product Inventory",
    path: "/app/purchase-inventory",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "inventory",
    isMenuOpen: false,
    menu: "purchase-inventory",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("PurchaseInventory"),
    child_routes: [
      {
        path: "/app/purchase-inventory/purchase",
        menu_title: "Purchase Inventory",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.PurchaseInventory !== undefined
            ? permission &&
            permission.PurchaseInventory.includes("Purchase_master")
            : false,
      },
      {
        path: "/app/purchase-inventory/purchase-history",
        menu_title: "Purchase History",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.PurchaseInventory !== undefined
            ? permission &&
            permission.PurchaseInventory.includes("Purchase_history")
            : false,
      },
      {
        path: "/app/purchase-inventory/inventory-report",
        menu_title: "Inventory Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.PurchaseInventory !== undefined
            ? permission &&
            permission.PurchaseInventory.includes("Inventory_report")
            : false,
      },
    ],
  },
  {
    menu_title: "Settings",
    path: "/app/setting",
    icon: <i className="fa fa-cog" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission: auth.includes("SettingsMaster"),
    child_routes: null,
  },
];

const MenuItems = sidebarLinks.filter(item => item.permission);

export default MenuItems;
